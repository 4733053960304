import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, createBlock as _createBlock, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57c49e70"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "printTable" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["draggable", "onDragstart", "onDrop", "onContextmenu", "onMousedown", "onDblclick"]
const _hoisted_4 = ["onMousedown"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    ref: $setup.setElements($props.object, $props.index),
    onMousedown: _cache[2] || (_cache[2] = _withModifiers($event => ($setup.handleMouseDown($event, $props.object)), ["left"])),
    onMouseup: _cache[3] || (_cache[3] = $event => ($setup.handleMouseUp($event, $setup.width))),
    onMousemove: _cache[4] || (_cache[4] = $event => ($setup.mouseMoveHandler($event, $setup.width))),
    onMouseleave: _cache[5] || (_cache[5] = $event => ($setup.mouseLeaveHandler($setup.width))),
    style: _normalizeStyle([$setup.postionalStyles($setup.startX, $setup.startY, $setup.width, $setup.height)]),
    class: _normalizeClass([
			'table-container',
			$setup.classes,
			$setup.MainStore.getCurrentElementsId.includes($setup.id) && 'active-elements',
		])
  }, [
    _createElementVNode("div", {
      style: _normalizeStyle(['overflow: hidden;', $setup.widthHeightStyle($setup.width - 2, $setup.height)]),
      onClick: _cache[1] || (_cache[1] = _withModifiers(
				() => {
					$setup.selectedColumn = null;
					$setup.selectedDynamicText = null;
				}
			, ["stop","self"]))
    }, [
      _createElementVNode("table", _hoisted_1, [
        _createElementVNode("thead", null, [
          ($setup.columns.length)
            ? (_openBlock(), _createElementBlock("tr", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.columns, (column, index) => {
                  return (_openBlock(), _createElementBlock("th", {
                    style: _normalizeStyle([
								'cursor: pointer;',
								column.width && {
									width: `${column.width}%`,
									maxWidth: `${column.width}%`,
								},
								$setup.headerStyle,
								column.applyStyleToHeader && column.style,
							]),
                    class: _normalizeClass(
								($setup.menu?.index == index || column == $setup.selectedColumn) &&
								'current-column'
							),
                    key: column.fieldname,
                    draggable: $setup.columnDragging,
                    onDragstart: $event => ($setup.dragstart($event, index)),
                    onDrop: $event => ($setup.drop($event, index)),
                    onDragleave: $setup.dragleave,
                    onDragover: $setup.allowDrop,
                    onContextmenu: _withModifiers($event => ($setup.handleMenu($event, index)), ["prevent"]),
                    onMousedown: _withModifiers($event => ($setup.handleColumnClick(column)), ["self"]),
                    onDblclick: _withModifiers($event => ($setup.handleDblClick($setup.table, column)), ["stop"]),
                    ref_for: true,
                    ref: 
								(el) => {
									column.DOMRef = el;
								}
							
                  }, [
                    _createElementVNode("span", {
                      class: _normalizeClass({ emptyColumnHead: !Boolean(column.label.length) })
                    }, _toDisplayString(column?.label), 3 /* TEXT, CLASS */),
                    _createElementVNode("div", {
                      class: "resizer",
                      draggable: "false",
                      ref_for: true,
                      ref: 
									(el) => {
										column.DOMRef = el;
									}
								,
                      onMousedown: _withModifiers($event => ($setup.mouseDownHandler($event, column)), ["left"]),
                      onMouseup: _cache[0] || (_cache[0] = _withModifiers((...args) => (_ctx.mouseUpHandler && _ctx.mouseUpHandler(...args)), ["left"])),
                      style: _normalizeStyle({
									height: $setup.DOMRef
										? $setup.DOMRef.firstChild.getBoundingClientRect().height + 'px'
										: '100%',
								})
                    }, null, 44 /* STYLE, PROPS, NEED_HYDRATION */, _hoisted_4)
                  ], 46 /* CLASS, STYLE, PROPS, NEED_HYDRATION */, _hoisted_3))
                }), 128 /* KEYED_FRAGMENT */))
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _createElementVNode("tbody", null, [
          ($setup.columns.length)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($setup.MainStore.docData[$setup.table?.fieldname]?.slice(
							0,
							$setup.PreviewRowNo || 3
						) || [{}], (row) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: row.idx
                }, [
                  _createVNode($setup["BaseTableTd"], _normalizeProps(_guardReactiveProps({
								row,
								columns: $setup.columns,
								style: $setup.style,
								labelStyle: $setup.labelStyle,
								selectedDynamicText: $setup.selectedDynamicText,
								setSelectedDynamicText: $setup.setSelectedDynamicText,
								table: $setup.table,
								altStyle: $setup.altStyle,
							})), null, 16 /* FULL_PROPS */)
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            : _createCommentVNode("v-if", true)
        ])
      ])
    ], 4 /* STYLE */),
    (
				$setup.MainStore.activeControl == 'mouse-pointer' &&
				$setup.MainStore.getCurrentElementsId.includes($setup.id)
			)
      ? (_openBlock(), _createBlock($setup["BaseResizeHandles"], { key: 0 }))
      : _createCommentVNode("v-if", true),
    ($setup.menu)
      ? (_openBlock(), _createBlock($setup["AppTableContextMenu"], _mergeProps({ key: 1 }, { menu: $setup.menu }, { onHandleMenuClick: $setup.handleMenuClick }), null, 16 /* FULL_PROPS */))
      : _createCommentVNode("v-if", true)
  ], 38 /* CLASS, STYLE, NEED_HYDRATION */))
}