export const globalStyles = {
	staticText: new Object({
		isGlobalStyle: true,
		styleEditMode: "main",
		labelDisplayStyle: "standard",
		type: "text",
		isDynamic: false,
		mainRuleSelector: ".staticText",
		style: {
			display: "inline-block",
			fontFamily: "Inter",
			fontSize: "14px",
			fontWeight: 400,
			color: "#000000",
			textAlign: "left",
			fontStyle: "normal",
			textDecoration: "none",
			textTransform: "none",
			lineHeight: 1.25,
			letterSpacing: "0px",
			contenteditable: false,
			border: "none",
			borderWidth: "0px",
			borderColor: "#000000",
			borderStyle: "solid",
			borderRadius: 0,
			backgroundColor: "",
			paddingTop: "0px",
			paddingBottom: "0px",
			paddingLeft: "0px",
			paddingRight: "0px",
			margin: "0px",
			minWidth: "0px",
			minHeight: "0px",
			boxShadow: "none",
			overflow: "hidden",
			overflowWrap: "break-word",
			whiteSpace: "normal",
			userSelect: "none",
			opacity: 1,
			zIndex: 1,
		},
	}),
	dynamicText: new Object({
		isGlobalStyle: true,
		styleEditMode: "main",
		labelDisplayStyle: "standard",
		type: "text",
		isDynamic: true,
		mainRuleSelector: ".dynamicText",
		labelRuleSelector: ".dynamicText .label-text",
		style: {
			display: "inline-block",
			fontFamily: "Inter",
			fontSize: "14px",
			fontWeight: 400,
			color: "#000000",
			textAlign: "left",
			fontStyle: "normal",
			textDecoration: "none",
			textTransform: "none",
			lineHeight: 1.25,
			letterSpacing: "0px",
			contenteditable: false,
			border: "none",
			borderWidth: "0px",
			borderColor: "#000000",
			borderStyle: "solid",
			borderRadius: 0,
			backgroundColor: "",
			paddingTop: "0px",
			paddingBottom: "0px",
			paddingLeft: "0px",
			paddingRight: "0px",
			margin: "0px",
			minWidth: "0px",
			minHeight: "0px",
			boxShadow: "none",
			overflow: "hidden",
			overflowWrap: "break-word",
			whiteSpace: "normal",
			userSelect: "none",
			opacity: 1,
		},
		labelStyle: {
			fontFamily: "Inter",
			fontSize: "14px",
			fontWeight: 600,
			color: "#000000",
			textAlign: "left",
			fontStyle: "normal",
			textDecoration: "none",
			textTransform: "none",
			lineHeight: 1.25,
			letterSpacing: "0px",
			border: "none",
			borderWidth: "0px",
			borderColor: "#000000",
			borderStyle: "solid",
			borderRadius: 0,
			backgroundColor: "",
			paddingTop: "0px",
			paddingBottom: "0px",
			paddingLeft: "0px",
			paddingRight: "0px",
			margin: "0px",
			minWidth: "0px",
			minHeight: "0px",
			boxShadow: "none",
			whiteSpace: "normal",
			userSelect: "none",
			opacity: 1,
			zIndex: 1,
		},
	}),
	rectangle: new Object({
		isGlobalStyle: true,
		styleEditMode: "main",
		type: "rectangle",
		isDynamic: false,
		mainRuleSelector: ".rectangle",
		style: {
			display: "inline-flex",
			justifyContent: "flex-start",
			alignItems: "flex-start",
			alignContent: "flex-start",
			flex: 1,
			paddingTop: "0px",
			paddingBottom: "0px",
			paddingLeft: "0px",
			paddingRight: "0px",
			margin: "0px",
			whiteSpace: "normal",
			userSelect: "none",
			minWidth: "0px",
			minHeight: "0px",
			color: "#000000",
			backgroundColor: "",
			border: "1px solid black",
			borderColor: "#000000",
			borderStyle: "solid",
			borderWidth: "1px",
			boxSizing: "border-box",
			outline: "none",
			borderRadius: 0,
			boxShadow: "none",
			opacity: 1,
			zIndex: 0,
		},
	}),
	image: new Object({
		isGlobalStyle: true,
		styleEditMode: "main",
		type: "image",
		isDynamic: false,
		mainRuleSelector: ".image",
		style: {
			display: "block",
			border: "none",
			borderWidth: "0px",
			borderColor: "#000000",
			borderStyle: "solid",
			borderRadius: 0,
			backgroundColor: "",
			paddingTop: "0px",
			paddingBottom: "0px",
			paddingLeft: "0px",
			paddingRight: "0px",
			margin: "0px",
			minWidth: "0px",
			minHeight: "0px",
			boxShadow: "none",
			whiteSpace: "normal",
			userSelect: "none",
			objectFit: "scale-down",
			objectPosition: "center center",
			// wkhtmltopdf doesn't support object-fit and object-position :(
			backgroundSize: "contain",
			backgroundPosition: "center center",
			backgroundRepeat: "no-repeat",
			opacity: 1,
		},
	}),
	barcode: new Object({
		isGlobalStyle: true,
		styleEditMode: "main",
		type: "barcode",
		isDynamic: false,
		mainRuleSelector: ".barcode",
		style: {
			display: "block",
			border: "none",
			borderWidth: "0px",
			borderColor: "#000000",
			borderStyle: "solid",
			borderRadius: 0,
			backgroundColor: "",
			paddingTop: "0px",
			paddingBottom: "0px",
			paddingLeft: "0px",
			paddingRight: "0px",
			margin: "0px",
			minWidth: "0px",
			minHeight: "0px",
			boxShadow: "none",
			whiteSpace: "normal",
			userSelect: "none",
			opacity: 1,
		},
	}),
	table: new Object({
		isGlobalStyle: true,
		styleEditMode: "main",
		labelDisplayStyle: "standard",
		type: "table",
		isDynamic: true,
		mainRuleSelector: ".printTable td",
		headerRuleSelector: ".printTable th",
		altRuleSelector: ".printTable tr:nth-child(even) td",
		labelRuleSelector: ".printTable .label-text",
		style: {
			fontFamily: "Inter",
			fontSize: "10px",
			fontWeight: 400,
			color: "#000000",
			textAlign: "left",
			fontStyle: "normal",
			textDecoration: "none",
			textTransform: "none",
			lineHeight: 1.25,
			letterSpacing: "0px",
			contenteditable: false,
			border: "none",
			borderWidth: "1px",
			borderColor: "#000000",
			borderStyle: "solid",
			borderRadius: 0,
			backgroundColor: "#ffffff",
			paddingTop: "10px",
			paddingBottom: "10px",
			paddingLeft: "10px",
			paddingRight: "10px",
			margin: "0px",
			minWidth: "0px",
			minHeight: "0px",
			boxShadow: "none",
			overflowWrap: "break-word",
			whiteSpace: "normal",
			userSelect: "none",
			verticalAlign: "baseline",
			opacity: 1,
		},
		labelStyle: {
			fontFamily: "Inter",
			fontSize: "10px",
			fontWeight: 600,
			color: "#000000",
			textAlign: "left",
			fontStyle: "normal",
			textDecoration: "none",
			textTransform: "none",
			lineHeight: 1.25,
			letterSpacing: "0px",
			border: "none",
			borderWidth: "0px",
			borderColor: "#000000",
			borderStyle: "solid",
			borderRadius: 0,
			backgroundColor: "#ffffff",
			paddingTop: "0px",
			paddingBottom: "0px",
			paddingLeft: "0px",
			paddingRight: "0px",
			margin: "0px",
			minWidth: "0px",
			minHeight: "0px",
			boxShadow: "none",
			whiteSpace: "normal",
			userSelect: "none",
			opacity: 1,
			verticalAlign: "baseline",
			zIndex: 1,
		},
		headerStyle: {
			fontFamily: "Inter",
			fontSize: "11px",
			fontWeight: 600,
			color: "#000000",
			textAlign: "left",
			fontStyle: "normal",
			textDecoration: "none",
			textTransform: "none",
			lineHeight: 1.25,
			letterSpacing: "0px",
			border: "none",
			borderWidth: "1px",
			borderColor: "#000000",
			borderStyle: "solid",
			borderRadius: 0,
			backgroundColor: "#ffffff",
			paddingTop: "10px",
			paddingBottom: "10px",
			paddingLeft: "10px",
			paddingRight: "10px",
			margin: "0px",
			minWidth: "0px",
			minHeight: "0px",
			boxShadow: "none",
			whiteSpace: "normal",
			userSelect: "none",
			opacity: 1,
			zIndex: 1,
		},
		altStyle: {},
	}),
};
